<template>
  <div class="vx-row">
    <!-- PDF Generator -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="`commande-${RefCmmd}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"

      ref="html2Pdf"
    >
      <section slot="pdf-content" class="pr-10 pl-10">
        <div class="vx-row">
          <div class="vx-col w-full">
            <!-- INVOICE METADATA -->

            <div class="vx-row" style="margin-top: 132px">
              <div class="vx-col w-full">
                <table class="w-full mb-5" cellpadding="0" cellspacing="0">
                  <thead>
                    <tr class="trCustom">
                      <th class="text-right thCustom" style="border-width: 0px" colspan="12">
                        DATE: {{DateCommd | moment("calendar", "July 10 2011") }}
                      </th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-right-width: 0px;width: 50%" colspan="6">FACTURE N°: {{RefCmmd}} </th>
                      <th class="thCustom" style="width: 50%" colspan="6">
                        Client: {{client.Civilite ? client.Civilite : ''}}. {{client.Nom}} {{client.Prenoms}} <br>
                        Adresse: {{client.Adresse.mobile}} /{{client.Adresse.tel}} <br>
                        Email: {{client.Adresse.email}}

                      </th>
                    </tr>
                    <tr class="trCustom" v-if="RefPEC">
                      <td style="border-width: 0px;width: 50%" colspan="6">

                      </td>
                      <td class="thCustom" style="border-width: 0px;width: 50%" colspan="6">
                        Entreprise:  {{entrepise}} <br>
                        Assurance:  {{assuran}} <br>
                        Matricule:  {{matricule}} <br>
                        Assuré:  {{assur}} <br>
                        Patient:  {{patient}} <br>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="vx-col md:w-2/4">
                <div class="w-full"></div>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full">
                <table class="w-full"  cellpadding="0" cellspacing="0">
                  <thead>
                    <tr class="trCustom">
                      <th class="thCustom" style="border: none"></th>
                      <th class="thCustom" style="border-width: 0px;">Sph</th>
                      <th class="thCustom" style="border-width: 0px;">Cyl</th>
                      <th class="thCustom" style="border-width: 0px;">Axe</th>
                      <th class="thCustom" style="border-width: 0px;">Add</th>
                      <th class="thCustom" style="border-width: 0px;">Diam</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-width: 0px;">OD</th>
                      <th class="thCustom" style="border-width: 0px;">{{SphVD}}</th>
                      <th class="thCustom" style="border-width: 0px;">{{CylVD}}</th>
                      <th class="thCustom" style="border-width: 0px;">{{AxeVD}}°</th>
                      <th class="thCustom" style="border-width: 0px;">{{AddVD}}</th>
                      <th class="thCustom" style="border-width: 0px;"></th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-width: 0px;">OG</th>
                      <th class="thCustom" style="border-width: 0px;">{{SphVG}}</th>
                      <th class="thCustom" style="border-width: 0px;">{{CylVG}}</th>
                      <th class="thCustom" style="border-width: 0px;">{{AxeVG}}°</th>
                      <th class="thCustom" style="border-width: 0px;">{{AddVG}}</th>
                      <th class="thCustom" style="border-width: 0px;"></th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>

            <template>
              <table class="w-full mt-5 tableCustom" cellpadding="0" cellspacing="0">
                <thead>
                  <template v-if="(montureClearName1 && montureClearName1 !='') || (RefVerG1 && RefVerG1 !='') || (RefVerD1 && RefVerD1 !='') ">
                    <tr class="trCustom">
                      <th class="thCustom text-center" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="8">Désignation</th>
                      <th class="thCustom text-center" style="border-bottom-width: 0px;" colspan="2">Prix</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="8">
                        <strong>MONTURE 1: </strong> {{montureClearName1}}
                      </th>
                      <th class="text-right thCustom" style="border-bottom-width: 0px;" colspan="2">{{moneyFormatter(PrixMont1)}}</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="8">
                        <template v-if="RefVerD1 === RefVerG1">
                          <strong>Verre ODG: </strong>{{RefVerD1}}
                        </template>
                        <template v-else>
                          <strong>Verre OD: </strong>{{RefVerD1}} <br>
                          <strong>Verre OG: </strong>{{RefVerG1}}
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-bottom-width: 0px;" colspan="2">
                        {{moneyFormatter(totalVerreProposition1 - totalSupProposition1)}}
                      </th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="8">
                        <template v-if="Id_TraitementOD1 || Id_TraitementOG1">
                          <template v-if="Id_TraitementOD1 === Id_TraitementOG1">
                            Traitement ODG: <strong> {{getTraitementById(Id_TraitementOD1).Libelle_Traitement}}  </strong>
                          </template>
                          <template v-else>
                            Traitement OD: <strong> {{getTraitementById(Id_TraitementOD1).Libelle_Traitement}}  </strong>
                            <br>
                            Traitement OG: <strong> {{getTraitementById(Id_TraitementOG1).Libelle_Traitement}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD1 || Id_ColorationOG1">
                          <template v-if="Id_ColorationOD1 === Id_ColorationOG1">
                            Coloration ODG: <strong> {{getColorationById(Id_ColorationOD1).Libelle_Coloration}}  </strong>
                          </template>
                          <template v-else>
                            Coloration OD: <strong> {{getColorationById(Id_TraitementOD1).Libelle_Coloration}}  </strong>
                            <br>
                            Coloration OG: <strong> {{getColorationById(Id_TraitementOG1).Libelle_Coloration}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD1 || Id_FabricationOG1">
                          <template v-if="Id_FabricationOD1 === Id_FabricationOG1">
                            Supplement ODG: <strong> {{getFabricationById(Id_FabricationOD1).Libelle_Fabrication}}  </strong>
                          </template>
                          <template v-else>
                            Supplement OD: <strong> {{getFabricationById(Id_FabricationOD1).Libelle_Fabrication}}  </strong>
                            <br>
                            Supplement OG: <strong> {{getFabricationById(Id_FabricationOG1).Libelle_Fabrication}}  </strong>
                          </template>
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-bottom-width: 0px;" colspan="2">
                        <template v-if="Id_TraitementOD1 || Id_TraitementOG1">
                          <template v-if="Id_TraitementOD1 === Id_TraitementOG1">
                            <strong> {{moneyFormatter((+(Prix_TraitementOD1 ? Prix_TraitementOD1 : 0)+ +(Prix_TraitementOG1 ? Prix_TraitementOG1 : 0)))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_TraitementOD1 ? Prix_TraitementOD1 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_TraitementOG1 ? Prix_TraitementOG1 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD1 || Id_ColorationOG1">
                          <template v-if="Id_ColorationOD1 === Id_ColorationOG1">
                            <strong> {{moneyFormatter(+(Prix_ColorationOD1 ? Prix_ColorationOD1 : 0) + +(Prix_ColorationOG1 ? Prix_ColorationOG1 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_ColorationOD1 ? Prix_ColorationOD1 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_ColorationOG1 ? Prix_ColorationOG1 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD1 || Id_FabricationOG1">
                          <template v-if="Id_FabricationOD1 === Id_FabricationOG1">
                            <strong> {{moneyFormatter(+(Prix_FabricationOD1 ? Prix_FabricationOD1 : 0) + +(Prix_FabricationOG1 ? Prix_FabricationOG1 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_FabricationOD1 ? Prix_FabricationOD1 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_FabricationOG1 ? Prix_FabricationOG1 : 0))}}  </strong>
                          </template>
                        </template>
                      </th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-right-width: 0px; border-top-style: dotted" colspan="8">
                        <strong>S / TOTAL: </strong>
                      </th>
                      <th class="text-right thCustom" colspan="2" style="border-top-style: dotted">
                        {{ moneyFormatter((+(PrixMont1 ? PrixMont1 : 0) + +(PrixVerG1 ? PrixVerG1 : 0) + +(PrixVerD1 ? PrixVerD1 : 0)))}}
                      </th>
                    </tr>

                  </template>

                  <template v-if="(montureClearName2 && montureClearName2 !='') || (RefVerG2 && RefVerG2 !='') || (RefVerD2 && RefVerD2 !='')">
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px; border-right-width: 0px;"  colspan="8">
                        <strong>MONTURE 2: </strong> {{montureClearName2}}
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px;" colspan="2">{{moneyFormatter(PrixMont2)}}</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px; border-right-width: 0px;"  colspan="8">
                        <template v-if="RefVerD2 === RefVerG2">
                          <strong>Verre ODG: </strong>{{RefVerD2}}
                        </template>
                        <template v-else>
                          <strong>Verre OD: </strong>{{RefVerD2}} <br>
                          <strong>Verre OG: </strong>{{RefVerG2}}
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px;" colspan="2">{{moneyFormatter(totalVerreProposition2)}}</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;"  colspan="8">
                        <template v-if="Id_TraitementOD2 || Id_TraitementOG2">
                          <template v-if="Id_TraitementOD2 === Id_TraitementOG2">
                            Traitement ODG: <strong> {{getTraitementById(Id_TraitementOD2).Libelle_Traitement}}  </strong>
                          </template>
                          <template v-else>
                            Traitement OD: <strong> {{getTraitementById(Id_TraitementOD2).Libelle_Traitement}}  </strong>
                            <br>
                            Traitement OG: <strong> {{getTraitementById(Id_TraitementOG2).Libelle_Traitement}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD2 || Id_ColorationOG2">
                          <template v-if="Id_ColorationOD2 === Id_ColorationOG2">
                            Coloration ODG: <strong> {{getColorationById(Id_ColorationOD2).Libelle_Coloration}}  </strong>
                          </template>
                          <template v-else>
                            Coloration OD: <strong> {{getColorationById(Id_TraitementOD2).Libelle_Coloration}}  </strong>
                            <br>
                            Coloration OG: <strong> {{getColorationById(Id_TraitementOG2).Libelle_Coloration}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD2 || Id_FabricationOG2">
                          <template v-if="Id_FabricationOD2 === Id_FabricationOG2">
                            Supplement ODG: <strong> {{getFabricationById(Id_FabricationOD2).Libelle_Fabrication}}  </strong>
                          </template>
                          <template v-else>
                            Supplement OD: <strong> {{getFabricationById(Id_FabricationOD2).Libelle_Fabrication}}  </strong>
                            <br>
                            Supplement OG: <strong> {{getFabricationById(Id_FabricationOG2).Libelle_Fabrication}}  </strong>
                          </template>
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px; border-bottom-width: 0px;" colspan="2">
                        <template v-if="Id_TraitementOD2 || Id_TraitementOG2">
                          <template v-if="Id_TraitementOD2 === Id_TraitementOG2">
                            <strong> {{moneyFormatter((+(Prix_TraitementOD2 ? Prix_TraitementOD2 : 0) + +(Prix_TraitementOG2 ? Prix_TraitementOG2 : 0)))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_TraitementOD2 ? Prix_TraitementOD2 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_TraitementOG2 ? Prix_TraitementOG2 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD2 || Id_ColorationOG2">
                          <template v-if="Id_ColorationOD2 === Id_ColorationOG2">
                            <strong> {{moneyFormatter(+(Prix_ColorationOD2 ? Prix_ColorationOD2 : 0) + +(Prix_ColorationOG2 ? Prix_ColorationOG2 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_ColorationOD2 ? Prix_ColorationOD2 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_ColorationOG2 ? Prix_ColorationOG2 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD2 || Id_FabricationOG2">
                          <template v-if="Id_FabricationOD2 === Id_FabricationOG2">
                            <strong> {{moneyFormatter(+(Prix_FabricationOD2 ? Prix_FabricationOD2 : 0) + +(Prix_FabricationOG2 ? Prix_FabricationOG2 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_FabricationOD2 ? Prix_FabricationOD2 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_FabricationOG2 ? Prix_FabricationOG2 : 0))}}  </strong>
                          </template>
                        </template>
                      </th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-right-width: 0px; border-top-style: dotted" colspan="8">
                        <strong>S / TOTAL: </strong>
                      </th>
                      <th class="text-right thCustom" colspan="2" style="border-top-style: dotted">
                        {{ moneyFormatter((+(PrixMont2 ? PrixMont2 : 0) + +(PrixVerG2 ? PrixVerG2 : 0) + +(PrixVerD2 ? PrixVerD2 : 0) + +(Prix_TraitementOD2 ? Prix_TraitementOD2 : 0) + +(Prix_TraitementOG2 ? Prix_TraitementOG2 : 0) + +(Prix_ColorationOD2 ? Prix_ColorationOD2 : 0) + +(Prix_ColorationOG2 ? Prix_ColorationOG2 : 0) + +(Prix_FabricationOD2 ? Prix_FabricationOD2 : 0) + +(Prix_FabricationOG2 ? Prix_FabricationOG2 : 0)))}}
                      </th>
                    </tr>
                  </template>

                  <template v-if="(montureClearName3 && montureClearName3 !='') || (RefVerG3 && RefVerG3 !='') || (RefVerD3 && RefVerD3 !='')">
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px; border-right-width: 0px;"  colspan="8">
                        <strong>MONTURE 3: </strong> {{montureClearName3}}
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px;" colspan="2">{{moneyFormatter(PrixMont3)}}</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px; border-right-width: 0px;"  colspan="8">
                        <template v-if="RefVerD3 === RefVerG3">
                          <strong>Verre ODG: </strong>{{RefVerD3}}
                        </template>
                        <template v-else>
                          <strong>Verre OD: </strong>{{RefVerD3}} <br>
                          <strong>Verre OG: </strong>{{RefVerG3}}
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px;" colspan="2">{{moneyFormatter(totalVerreProposition3)}}</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;"  colspan="8">
                        <template v-if="Id_TraitementOD3 || Id_TraitementOG3">
                          <template v-if="Id_TraitementOD3 === Id_TraitementOG3">
                            Traitement ODG: <strong> {{getTraitementById(Id_TraitementOD3).Libelle_Traitement}}  </strong>
                          </template>
                          <template v-else>
                            Traitement OD: <strong> {{getTraitementById(Id_TraitementOD3).Libelle_Traitement}}  </strong>
                            <br>
                            Traitement OG: <strong> {{getTraitementById(Id_TraitementOG3).Libelle_Traitement}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD3 || Id_ColorationOG3">
                          <template v-if="Id_ColorationOD3 === Id_ColorationOG3">
                            Coloration ODG: <strong> {{getColorationById(Id_ColorationOD3).Libelle_Coloration}}  </strong>
                          </template>
                          <template v-else>
                            Coloration OD: <strong> {{getColorationById(Id_TraitementOD3).Libelle_Coloration}}  </strong>
                            <br>
                            Coloration OG: <strong> {{getColorationById(Id_TraitementOG3).Libelle_Coloration}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD3 || Id_FabricationOG3">
                          <template v-if="Id_FabricationOD3 === Id_FabricationOG3">
                            Supplement ODG: <strong> {{getFabricationById(Id_FabricationOD3).Libelle_Fabrication}}  </strong>
                          </template>
                          <template v-else>
                            Supplement OD: <strong> {{getFabricationById(Id_FabricationOD3).Libelle_Fabrication}}  </strong>
                            <br>
                            Supplement OG: <strong> {{getFabricationById(Id_FabricationOG3).Libelle_Fabrication}}  </strong>
                          </template>
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px; border-bottom-width: 0px;" colspan="2">
                        <template v-if="Id_TraitementOD3 || Id_TraitementOG3">
                          <template v-if="Id_TraitementOD3 === Id_TraitementOG3">
                            <strong> {{moneyFormatter((+(Prix_TraitementOD3 ? Prix_TraitementOD3 : 0) + +(Prix_TraitementOG3 ? Prix_TraitementOG3 : 0)))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_TraitementOD3 ? Prix_TraitementOD3 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_TraitementOG3 ? Prix_TraitementOG3 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD3 || Id_ColorationOG3">
                          <template v-if="Id_ColorationOD3 === Id_ColorationOG3">
                            <strong> {{moneyFormatter(+(Prix_ColorationOD3 ? Prix_ColorationOD3 : 0) + +(Prix_ColorationOG3 ? Prix_ColorationOG3 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_ColorationOD3 ? Prix_ColorationOD3 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_ColorationOG3 ? Prix_ColorationOG3 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD3 || Id_FabricationOG3">
                          <template v-if="Id_FabricationOD3 === Id_FabricationOG3">
                            <strong> {{moneyFormatter(+(Prix_FabricationOD3 ? Prix_FabricationOD3 : 0) + +(Prix_FabricationOG3 ? Prix_FabricationOG3 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_FabricationOD3 ? Prix_FabricationOD3 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_FabricationOG3 ? Prix_FabricationOG3 : 0))}}  </strong>
                          </template>
                        </template>
                      </th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-right-width: 0px; border-top-style: dotted" colspan="8">
                        <strong>S / TOTAL: </strong>
                      </th>
                      <th class="text-right thCustom" colspan="2" style="border-top-style: dotted">
                        {{ moneyFormatter((+(PrixMont3 ? PrixMont3 : 0) + +(PrixVerG3 ? PrixVerG3 : 0) + +(PrixVerD3 ? PrixVerD3 : 0) + +(Prix_TraitementOD3 ? Prix_TraitementOD3 : 0) + +(Prix_TraitementOG3 ? Prix_TraitementOG3 : 0) + +(Prix_ColorationOD3 ? Prix_ColorationOD3 : 0) + +(Prix_ColorationOG3 ? Prix_ColorationOG3 : 0) + +(Prix_FabricationOD3 ? Prix_FabricationOD3 : 0) + +(Prix_FabricationOG3 ? Prix_FabricationOG3 : 0)))}}
                      </th>
                    </tr>
                  </template>
                  <tr class="trCustom">
                    <th class="thCustom" style="border-width: 0px;" colspan="6">
                      <strong>Ticket: </strong> {{ticket}} %<br> <br>
                      <strong>Plafon: </strong> {{moneyFormatter(plafon)}}
                    </th>
                    <th class="thCustom" style="border-width: 0px;" colspan="2">
                      <strong>TOTAL </strong> <br/> <br>
                      <strong>REMISE </strong> <br/> <br>
                      <strong>NET A PAYER </strong>
                    </th>
                    <th class="text-right thCustom" style="border-top-width: 0px;" colspan="2">
                     {{moneyFormatter(totalSansRemise)}} <br/> <br>
                     {{moneyFormatter(RemiseCom)}} <br/> <br>
                     {{moneyFormatter(calculNet)}}
                    </th>
                  </tr>
                </thead>
              </table>


              <div class="vx-row mt-5" style="margin-bottom: 57px">
                <div class="vx-col md:w-2/3">
                  <table class="w-full tableCustom" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr class="trCustom">
                        <th class="thCustom" colspan="4">
                          Arrêté la facture à la somme de: {{NumberToLetter(factureNet)}}<br>
                          {{moneyFormatter(factureNet)}} Fcfa soit  <br>
                          -part client: {{moneyFormatter(calculParClient)}} Fcfa<br>
                          -part assurance: {{ moneyFormatter( calculParAssurance)}} Fcfa
                        </th>
                        <th class="thCustom" style="border-width: 0px;" colspan="6">

                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="vx-col md:w-1/3">
                  <p>Représentant</p>
                </div>
              </div>

            </template>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <!-- PDF Generator end -->

    <div  class="vx-col md:w-3/4">
      <vx-card id="corps-proforma">
        <div class="vx-row">
          <div class="vx-col w-full">
            <!-- INVOICE METADATA -->

            <div class="vx-row" style="margin-top: 132px">
              <div class="vx-col w-full">
                <table class="w-full mb-5" cellpadding="0" cellspacing="0">
                  <thead>
                    <tr class="trCustom">
                      <th class="text-right thCustom" style="border-width: 0px" colspan="12">
                        DATE: {{DateCommd | moment("calendar", "July 10 2011") }}
                      </th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-right-width: 0px;width: 50%" colspan="6">FACTURE N°: {{RefCmmd}} </th>
                      <th class="thCustom" style="width: 50%" colspan="6">
                        Client: {{client.Civilite ? client.Civilite : ''}}. {{client.Nom}} {{client.Prenoms}} <br>
                        Adresse: {{client.Adresse.mobile}} /{{client.Adresse.tel}} <br>
                        Email: {{client.Adresse.email}}

                      </th>
                    </tr>
                    <tr class="trCustom" v-if="RefPEC">
                      <td style="border-width: 0px;width: 50%" colspan="6">

                      </td>
                      <td class="thCustom" style="border-width: 0px;width: 50%" colspan="6">
                        Entreprise:  {{entrepise}} <br>
                        Assurance:  {{assuran}} <br>
                        Matricule:  {{matricule}} <br>
                        Assuré:  {{assur}} <br>
                        Patient:  {{patient}} <br>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="vx-col md:w-2/4">
                <div class="w-full"></div>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full">
                <table class="w-full"  cellpadding="0" cellspacing="0">
                  <thead>
                    <tr class="trCustom">
                      <th class="thCustom" style="border: none"></th>
                      <th class="thCustom" style="border-width: 0px;">Sph</th>
                      <th class="thCustom" style="border-width: 0px;">Cyl</th>
                      <th class="thCustom" style="border-width: 0px;">Axe</th>
                      <th class="thCustom" style="border-width: 0px;">Add</th>
                      <th class="thCustom" style="border-width: 0px;">Diam</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-width: 0px;">OD</th>
                      <th class="thCustom" style="border-width: 0px;">{{SphVD}}</th>
                      <th class="thCustom" style="border-width: 0px;">{{CylVD}}</th>
                      <th class="thCustom" style="border-width: 0px;">{{AxeVD}}°</th>
                      <th class="thCustom" style="border-width: 0px;">{{AddVD}}</th>
                      <th class="thCustom" style="border-width: 0px;"></th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-width: 0px;">OG</th>
                      <th class="thCustom" style="border-width: 0px;">{{SphVG}}</th>
                      <th class="thCustom" style="border-width: 0px;">{{CylVG}}</th>
                      <th class="thCustom" style="border-width: 0px;">{{AxeVG}}°</th>
                      <th class="thCustom" style="border-width: 0px;">{{AddVG}}</th>
                      <th class="thCustom" style="border-width: 0px;"></th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>

            <template>
              <table class="w-full mt-5 tableCustom" cellpadding="0" cellspacing="0">
                <thead>
                  <template v-if="(montureClearName1 && montureClearName1 !='') || (RefVerG1 && RefVerG1 !='') || (RefVerD1 && RefVerD1 !='') ">
                    <tr class="trCustom">
                      <th class="thCustom text-center" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="8">Désignation</th>
                      <th class="thCustom text-center" style="border-bottom-width: 0px;" colspan="2">Prix</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="8">
                        <strong>MONTURE 1: </strong> {{montureClearName1}}
                      </th>
                      <th class="text-right thCustom" style="border-bottom-width: 0px;" colspan="2">{{moneyFormatter(PrixMont1)}}</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="8">
                        <template v-if="RefVerD1 === RefVerG1">
                          <strong>Verre ODG: </strong>{{RefVerD1}}
                        </template>
                        <template v-else>
                          <strong>Verre OD: </strong>{{RefVerD1}} <br>
                          <strong>Verre OG: </strong>{{RefVerG1}}
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-bottom-width: 0px;" colspan="2">
                        {{moneyFormatter(totalVerreProposition1 - totalSupProposition1)}}
                      </th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="8">
                        <template v-if="Id_TraitementOD1 || Id_TraitementOG1">
                          <template v-if="Id_TraitementOD1 === Id_TraitementOG1">
                            Traitement ODG: <strong> {{getTraitementById(Id_TraitementOD1).Libelle_Traitement}}  </strong>
                          </template>
                          <template v-else>
                            Traitement OD: <strong> {{getTraitementById(Id_TraitementOD1).Libelle_Traitement}}  </strong>
                            <br>
                            Traitement OG: <strong> {{getTraitementById(Id_TraitementOG1).Libelle_Traitement}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD1 || Id_ColorationOG1">
                          <template v-if="Id_ColorationOD1 === Id_ColorationOG1">
                            Coloration ODG: <strong> {{getColorationById(Id_ColorationOD1).Libelle_Coloration}}  </strong>
                          </template>
                          <template v-else>
                            Coloration OD: <strong> {{getColorationById(Id_TraitementOD1).Libelle_Coloration}}  </strong>
                            <br>
                            Coloration OG: <strong> {{getColorationById(Id_TraitementOG1).Libelle_Coloration}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD1 || Id_FabricationOG1">
                          <template v-if="Id_FabricationOD1 === Id_FabricationOG1">
                            Supplement ODG: <strong> {{getFabricationById(Id_FabricationOD1).Libelle_Fabrication}}  </strong>
                          </template>
                          <template v-else>
                            Supplement OD: <strong> {{getFabricationById(Id_FabricationOD1).Libelle_Fabrication}}  </strong>
                            <br>
                            Supplement OG: <strong> {{getFabricationById(Id_FabricationOG1).Libelle_Fabrication}}  </strong>
                          </template>
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-bottom-width: 0px;" colspan="2">
                        <template v-if="Id_TraitementOD1 || Id_TraitementOG1">
                          <template v-if="Id_TraitementOD1 === Id_TraitementOG1">
                            <strong> {{moneyFormatter((+(Prix_TraitementOD1 ? Prix_TraitementOD1 : 0)+ +(Prix_TraitementOG1 ? Prix_TraitementOG1 : 0)))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_TraitementOD1 ? Prix_TraitementOD1 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_TraitementOG1 ? Prix_TraitementOG1 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD1 || Id_ColorationOG1">
                          <template v-if="Id_ColorationOD1 === Id_ColorationOG1">
                            <strong> {{moneyFormatter(+(Prix_ColorationOD1 ? Prix_ColorationOD1 : 0) + +(Prix_ColorationOG1 ? Prix_ColorationOG1 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_ColorationOD1 ? Prix_ColorationOD1 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_ColorationOG1 ? Prix_ColorationOG1 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD1 || Id_FabricationOG1">
                          <template v-if="Id_FabricationOD1 === Id_FabricationOG1">
                            <strong> {{moneyFormatter(+(Prix_FabricationOD1 ? Prix_FabricationOD1 : 0) + +(Prix_FabricationOG1 ? Prix_FabricationOG1 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_FabricationOD1 ? Prix_FabricationOD1 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_FabricationOG1 ? Prix_FabricationOG1 : 0))}}  </strong>
                          </template>
                        </template>
                      </th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-right-width: 0px; border-top-style: dotted" colspan="8">
                        <strong>S / TOTAL: </strong>
                      </th>
                      <th class="text-right thCustom" colspan="2" style="border-top-style: dotted">
                        {{ moneyFormatter((+(PrixMont1 ? PrixMont1 : 0) + +(PrixVerG1 ? PrixVerG1 : 0) + +(PrixVerD1 ? PrixVerD1 : 0)))}}
                      </th>
                    </tr>

                  </template>

                  <template v-if="(montureClearName2 && montureClearName2 !='') || (RefVerG2 && RefVerG2 !='') || (RefVerD2 && RefVerD2 !='')">
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px; border-right-width: 0px;"  colspan="8">
                        <strong>MONTURE 2: </strong> {{montureClearName2}}
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px;" colspan="2">{{moneyFormatter(PrixMont2)}}</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px; border-right-width: 0px;"  colspan="8">
                        <template v-if="RefVerD2 === RefVerG2">
                          <strong>Verre ODG: </strong>{{RefVerD2}}
                        </template>
                        <template v-else>
                          <strong>Verre OD: </strong>{{RefVerD2}} <br>
                          <strong>Verre OG: </strong>{{RefVerG2}}
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px;" colspan="2">{{moneyFormatter(totalVerreProposition2)}}</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;"  colspan="8">
                        <template v-if="Id_TraitementOD2 || Id_TraitementOG2">
                          <template v-if="Id_TraitementOD2 === Id_TraitementOG2">
                            Traitement ODG: <strong> {{getTraitementById(Id_TraitementOD2).Libelle_Traitement}}  </strong>
                          </template>
                          <template v-else>
                            Traitement OD: <strong> {{getTraitementById(Id_TraitementOD2).Libelle_Traitement}}  </strong>
                            <br>
                            Traitement OG: <strong> {{getTraitementById(Id_TraitementOG2).Libelle_Traitement}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD2 || Id_ColorationOG2">
                          <template v-if="Id_ColorationOD2 === Id_ColorationOG2">
                            Coloration ODG: <strong> {{getColorationById(Id_ColorationOD2).Libelle_Coloration}}  </strong>
                          </template>
                          <template v-else>
                            Coloration OD: <strong> {{getColorationById(Id_TraitementOD2).Libelle_Coloration}}  </strong>
                            <br>
                            Coloration OG: <strong> {{getColorationById(Id_TraitementOG2).Libelle_Coloration}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD2 || Id_FabricationOG2">
                          <template v-if="Id_FabricationOD2 === Id_FabricationOG2">
                            Supplement ODG: <strong> {{getFabricationById(Id_FabricationOD2).Libelle_Fabrication}}  </strong>
                          </template>
                          <template v-else>
                            Supplement OD: <strong> {{getFabricationById(Id_FabricationOD2).Libelle_Fabrication}}  </strong>
                            <br>
                            Supplement OG: <strong> {{getFabricationById(Id_FabricationOG2).Libelle_Fabrication}}  </strong>
                          </template>
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px; border-bottom-width: 0px;" colspan="2">
                        <template v-if="Id_TraitementOD2 || Id_TraitementOG2">
                          <template v-if="Id_TraitementOD2 === Id_TraitementOG2">
                            <strong> {{moneyFormatter((+(Prix_TraitementOD2 ? Prix_TraitementOD2 : 0) + +(Prix_TraitementOG2 ? Prix_TraitementOG2 : 0)))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_TraitementOD2 ? Prix_TraitementOD2 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_TraitementOG2 ? Prix_TraitementOG2 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD2 || Id_ColorationOG2">
                          <template v-if="Id_ColorationOD2 === Id_ColorationOG2">
                            <strong> {{moneyFormatter(+(Prix_ColorationOD2 ? Prix_ColorationOD2 : 0) + +(Prix_ColorationOG2 ? Prix_ColorationOG2 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_ColorationOD2 ? Prix_ColorationOD2 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_ColorationOG2 ? Prix_ColorationOG2 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD2 || Id_FabricationOG2">
                          <template v-if="Id_FabricationOD2 === Id_FabricationOG2">
                            <strong> {{moneyFormatter(+(Prix_FabricationOD2 ? Prix_FabricationOD2 : 0) + +(Prix_FabricationOG2 ? Prix_FabricationOG2 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_FabricationOD2 ? Prix_FabricationOD2 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_FabricationOG2 ? Prix_FabricationOG2 : 0))}}  </strong>
                          </template>
                        </template>
                      </th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-right-width: 0px; border-top-style: dotted" colspan="8">
                        <strong>S / TOTAL: </strong>
                      </th>
                      <th class="text-right thCustom" colspan="2" style="border-top-style: dotted">
                        {{ moneyFormatter((+(PrixMont2 ? PrixMont2 : 0) + +(PrixVerG2 ? PrixVerG2 : 0) + +(PrixVerD2 ? PrixVerD2 : 0) + +(Prix_TraitementOD2 ? Prix_TraitementOD2 : 0) + +(Prix_TraitementOG2 ? Prix_TraitementOG2 : 0) + +(Prix_ColorationOD2 ? Prix_ColorationOD2 : 0) + +(Prix_ColorationOG2 ? Prix_ColorationOG2 : 0) + +(Prix_FabricationOD2 ? Prix_FabricationOD2 : 0) + +(Prix_FabricationOG2 ? Prix_FabricationOG2 : 0)))}}
                      </th>
                    </tr>
                  </template>

                  <template v-if="(montureClearName3 && montureClearName3 !='') || (RefVerG3 && RefVerG3 !='') || (RefVerD3 && RefVerD3 !='')">
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px; border-right-width: 0px;"  colspan="8">
                        <strong>MONTURE 3: </strong> {{montureClearName3}}
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px;" colspan="2">{{moneyFormatter(PrixMont3)}}</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px; border-right-width: 0px;"  colspan="8">
                        <template v-if="RefVerD3 === RefVerG3">
                          <strong>Verre ODG: </strong>{{RefVerD3}}
                        </template>
                        <template v-else>
                          <strong>Verre OD: </strong>{{RefVerD3}} <br>
                          <strong>Verre OG: </strong>{{RefVerG3}}
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px;" colspan="2">{{moneyFormatter(totalVerreProposition3)}}</th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;"  colspan="8">
                        <template v-if="Id_TraitementOD3 || Id_TraitementOG3">
                          <template v-if="Id_TraitementOD3 === Id_TraitementOG3">
                            Traitement ODG: <strong> {{getTraitementById(Id_TraitementOD3).Libelle_Traitement}}  </strong>
                          </template>
                          <template v-else>
                            Traitement OD: <strong> {{getTraitementById(Id_TraitementOD3).Libelle_Traitement}}  </strong>
                            <br>
                            Traitement OG: <strong> {{getTraitementById(Id_TraitementOG3).Libelle_Traitement}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD3 || Id_ColorationOG3">
                          <template v-if="Id_ColorationOD3 === Id_ColorationOG3">
                            Coloration ODG: <strong> {{getColorationById(Id_ColorationOD3).Libelle_Coloration}}  </strong>
                          </template>
                          <template v-else>
                            Coloration OD: <strong> {{getColorationById(Id_TraitementOD3).Libelle_Coloration}}  </strong>
                            <br>
                            Coloration OG: <strong> {{getColorationById(Id_TraitementOG3).Libelle_Coloration}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD3 || Id_FabricationOG3">
                          <template v-if="Id_FabricationOD3 === Id_FabricationOG3">
                            Supplement ODG: <strong> {{getFabricationById(Id_FabricationOD3).Libelle_Fabrication}}  </strong>
                          </template>
                          <template v-else>
                            Supplement OD: <strong> {{getFabricationById(Id_FabricationOD3).Libelle_Fabrication}}  </strong>
                            <br>
                            Supplement OG: <strong> {{getFabricationById(Id_FabricationOG3).Libelle_Fabrication}}  </strong>
                          </template>
                        </template>
                      </th>
                      <th class="text-right thCustom" style="border-top-width: 0px; border-bottom-width: 0px;" colspan="2">
                        <template v-if="Id_TraitementOD3 || Id_TraitementOG3">
                          <template v-if="Id_TraitementOD3 === Id_TraitementOG3">
                            <strong> {{moneyFormatter((+(Prix_TraitementOD3 ? Prix_TraitementOD3 : 0) + +(Prix_TraitementOG3 ? Prix_TraitementOG3 : 0)))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_TraitementOD3 ? Prix_TraitementOD3 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_TraitementOG3 ? Prix_TraitementOG3 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_ColorationOD3 || Id_ColorationOG3">
                          <template v-if="Id_ColorationOD3 === Id_ColorationOG3">
                            <strong> {{moneyFormatter(+(Prix_ColorationOD3 ? Prix_ColorationOD3 : 0) + +(Prix_ColorationOG3 ? Prix_ColorationOG3 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_ColorationOD3 ? Prix_ColorationOD3 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_ColorationOG3 ? Prix_ColorationOG3 : 0))}}  </strong>
                          </template>
                          <br>
                        </template>
                        <template v-if="Id_FabricationOD3 || Id_FabricationOG3">
                          <template v-if="Id_FabricationOD3 === Id_FabricationOG3">
                            <strong> {{moneyFormatter(+(Prix_FabricationOD3 ? Prix_FabricationOD3 : 0) + +(Prix_FabricationOG3 ? Prix_FabricationOG3 : 0))}}  </strong>
                          </template>
                          <template v-else>
                            <strong> {{moneyFormatter((Prix_FabricationOD3 ? Prix_FabricationOD3 : 0))}}  </strong>
                            <br>
                            <strong> {{moneyFormatter((Prix_FabricationOG3 ? Prix_FabricationOG3 : 0))}}  </strong>
                          </template>
                        </template>
                      </th>
                    </tr>
                    <tr class="trCustom">
                      <th class="thCustom" style="border-right-width: 0px; border-top-style: dotted" colspan="8">
                        <strong>S / TOTAL: </strong>
                      </th>
                      <th class="text-right thCustom" colspan="2" style="border-top-style: dotted">
                        {{ moneyFormatter((+(PrixMont3 ? PrixMont3 : 0) + +(PrixVerG3 ? PrixVerG3 : 0) + +(PrixVerD3 ? PrixVerD3 : 0) + +(Prix_TraitementOD3 ? Prix_TraitementOD3 : 0) + +(Prix_TraitementOG3 ? Prix_TraitementOG3 : 0) + +(Prix_ColorationOD3 ? Prix_ColorationOD3 : 0) + +(Prix_ColorationOG3 ? Prix_ColorationOG3 : 0) + +(Prix_FabricationOD3 ? Prix_FabricationOD3 : 0) + +(Prix_FabricationOG3 ? Prix_FabricationOG3 : 0)))}}
                      </th>
                    </tr>
                  </template>
                  <tr class="trCustom">
                    <th class="thCustom" style="border-width: 0px;" colspan="6">
                      <strong>Ticket: </strong> {{ticket}} %<br> <br>
                      <strong>Plafon: </strong> {{moneyFormatter(plafon)}}
                    </th>
                    <th class="thCustom" style="border-width: 0px;" colspan="2">
                      <strong>TOTAL </strong> <br/> <br>
                      <strong>REMISE </strong> <br/> <br>
                      <strong>NET A PAYER </strong>
                    </th>
                    <th class="text-right thCustom" style="border-top-width: 0px;" colspan="2">
                     {{moneyFormatter(totalSansRemise)}} <br/> <br>
                     {{moneyFormatter(RemiseCom)}} <br/> <br>
                     {{moneyFormatter(calculNet)}}
                    </th>
                  </tr>
                </thead>
              </table>


              <div class="vx-row mt-5" style="margin-bottom: 57px">
                <div class="vx-col md:w-2/3">
                  <table class="w-full tableCustom" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr class="trCustom">
                        <th class="thCustom" colspan="4">
                          Arrêté la facture à la somme de: {{NumberToLetter(factureNet)}}<br>
                          {{moneyFormatter(factureNet)}} Fcfa soit  <br>
                          -part client: {{moneyFormatter(calculParClient)}} Fcfa<br>
                          -part assurance: {{ moneyFormatter( calculParAssurance)}} Fcfa
                        </th>
                        <th class="thCustom" style="border-width: 0px;" colspan="6">

                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="vx-col md:w-1/3">
                  <p>Représentant</p>
                </div>
              </div>

            </template>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col md:w-1/4">
      <vx-card>
        <!-- <vs-select label="Prescripteur" disabled="true" autocomplete class="selectExample w-full select-large mt-4" v-model="Idprescripteur">
          <vs-select-item :key="index" :value="item._id" :text="`${item.NomPresc} ${item.PrenomPresc}`" v-for="(item,index) in prescripteurs" />
        </vs-select> -->
        <div class="mt-4">
          <vs-button color="primary" class="w-full" icon-pack="feather" icon="icon-file" @click="generateReport">IMPRIMER</vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'Add',
  components: {
    vSelect,
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      activeUserInfos: null,
      RefCmmd: null,
      RefOrd: null,
      client: {
        Civilite: '',
        Nom: '',
        Prenoms: '',
        Adresse : {
          mobile: '',
          tel: ''
        }
      },
      IdOfficine: null,
      IdClient: null,
      Idprescripteur: null,
      IdUser: null,
      IdPartenaire: null,
      DatePrescrit: null,
      DateCommd: null,
      SphVD: '',
      SphVG: '',
      CylVD: '',
      CylVG: '',
      AxeVD: '',
      AxeVG: '',
      AddVD: '',
      AddVG: '',

      Ht_Mont1: null,
      Dctr_Mont1: null,
      EcartVlOD: null,
      EcartVpOD: null,
      EcartVlOG: null,
      EcartVpOG: null,
      Ht_Mont2: null,
      Ht_Mont3: null,
      Dctr_Mont2: null,
      Dctr_Mont3: null,

      IdMont1: null,
      libelleMontureClient: '',
      OrigineMont1: '',
      PrixMont1: 0,
      RefVerG1: '',
      PrixVerG1: 0,
      RefVerD1: '',
      PrixVerD1: 0,
      SuplementOD1: [],
      SuplementOG1: [],

      IdMont2: null,
      libelleMontureClient2: '',
      OrigineMont2: '',
      PrixMont2: 0,
      RefVerG2: '',
      PrixVerG2: 0,
      RefVerD2: '',
      PrixVerD2: 0,
      SuplementOD2: [],
      SuplementOG2: [],

      IdMont3: null,
      libelleMontureClient3: '',
      OrigineMont3: '',
      PrixMont3: 0,
      RefVerG3: '',
      PrixVerG3: 0,
      RefVerD3: '',
      PrixVerD3: 0,
      SuplementOD3: [],
      SuplementOG3: [],

      Id_FoyerOD1: null,
      IdGammeVerrOD1: null,
      Id_OptionVerreOD1: null,
      Prix_OptionVerreOD1: 0,
      Id_MatiereOD1: null,
      Id_IndiceOD1: null,
      IdFourVerrOD1: null,
      Id_FabricationOD1: null,
      Prix_FabricationOD1: 0,
      Id_ColorationOD1: null,
      Prix_ColorationOD1: 0,
      Id_TraitementOD1: null,
      Prix_TraitementOD1: 0,

      Id_FoyerOG1: null,
      IdGammeVerrOG1: null,
      Id_OptionVerreOG1: null,
      Prix_OptionVerreOG1: 0,
      Id_MatiereOG1: null,
      Id_IndiceOG1: null,
      IdFourVerrOG1: null,
      Id_FabricationOG1: null,
      Prix_FabricationOG1: 0,
      Id_ColorationOG1: null,
      Prix_ColorationOG1: 0,
      Id_TraitementOG1: null,
      Prix_TraitementOG1: 0,

      Id_FoyerOD2: null,
      IdGammeVerrOD2: null,
      Id_OptionVerreOD2: null,
      Prix_OptionVerreOD2: 0,
      Id_MatiereOD2: null,
      Id_IndiceOD2: null,
      IdFourVerrOD2: null,
      Id_FabricationOD2: null,
      Prix_FabricationOD2: 0,
      Id_ColorationOD2: null,
      Prix_ColorationOD2: 0,
      Id_TraitementOD2: null,
      Prix_TraitementOD2: 0,

      Id_FoyerOG2: null,
      IdGammeVerrOG2: null,
      Id_OptionVerreOG2: null,
      Prix_OptionVerreOG2: 0,
      Id_MatiereOG2: null,
      Id_IndiceOG2: null,
      IdFourVerrOG2: null,
      Id_FabricationOG2: null,
      Prix_FabricationOG2: 0,
      Id_ColorationOG2: null,
      Prix_ColorationOG2: 0,
      Id_TraitementOG2: null,
      Prix_TraitementOG2: 0,

      Id_FoyerOD3: null,
      IdGammeVerrOD3: null,
      Id_OptionVerreOD3: null,
      Prix_OptionVerreOD3: 0,
      Id_MatiereOD3: null,
      Id_IndiceOD3: null,
      IdFourVerrOD3: null,
      Id_FabricationOD3: null,
      Prix_FabricationOD3: 0,
      Id_ColorationOD3: null,
      Prix_ColorationOD3: 0,
      Id_TraitementOD3: null,
      Prix_TraitementOD3: 0,

      Id_FoyerOG3: null,
      IdGammeVerrOG3: null,
      Id_OptionVerreOG3: null,
      Prix_OptionVerreOG3: 0,
      Id_MatiereOG3: null,
      Id_IndiceOG3: null,
      IdFourVerrOG3: null,
      Id_FabricationOG3: null,
      Prix_FabricationOG3: 0,
      Id_ColorationOG3: null,
      Prix_ColorationOG3: 0,
      Id_TraitementOG3: null,
      Prix_TraitementOG3: 0,

      Remarque: '',
      TotalFactBrut: 0,
      RemiseCom: 0,
      TotalFactNet: 0,
      PartClient: 0,
      PartAssu: 0,
      PECharge: false,
      CmdeTSalleMontage: false,
      CmdeAuFourVerre: false,
      CmdeMontageFin: false,
      ComdeLivreClient: false,

      propositions: [],

      montureClearName1: '',
      montureClearName2: '',
      montureClearName3: '',

      active: false,

      /* PDF Config params */
      showlayout: 'false',
      floatlayout: 'true',
      enabledownload: 'false',
      previewmodal: 'true',
      filename: 'Proforma',
      pdfquality: '2',
      manualpagination: 'false',
      pdfformat: 'a4',
      pdforientation: 'landscape',
      pdfcontentwidth: '100%',

      /* Societe */
      NomSociete:'',
      RegistreCom:'',

      /* Assurance */
      Nom_Abrege:'',
      Nom_Complet:'',
      adresse:'',
      pays:'',
      telephone:'',
      mobile:'',
      fax:'',
      Email:'',
      Site:'',
      RegistreComA:'',
      ComptCont:'',
      ComptBanq:'',
      DelaiRegl: null,

      /* Prise en charge */
      RefPEC: null,
      IdSociete: null,
      IdAssurance: null,
      RefCommande: '',
      NumPolice: '',
      Assurer: '',
      NumPatient: '',
      Patient: '',
      NumSalarie: '',
      Salarie: '',
      TickMont: '',
      TickVerr: '',
      PlafMont: '',
      PlafVerr: '',
      TickForf: '',
      PlafForf: '',
      QteMont: '',
      QteVerr: '',
      QtePair: '',

      /* info prise en charge */
      entrepise: '',
      assuran: '',
      matricule: '',
      assur: '',
      patient: '',
      ticket: 0,
      ticketM: 0,
      ticketV: 0,
      plafon: 0,
      plafonM: 0,
      plafonV: 0,
      qtePaire: 0,

      /* Montant facture net */
      factureNet: 0
    }
  },
  computed: {
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    },
    totalVerreProposition1 () {
      return (+(this.PrixVerG1 ? this.PrixVerG1 : 0) + +(this.PrixVerD1 ? this.PrixVerD1 : 0))
    },
    totalSupProposition1 () {
      const traitementOD = this.Prix_TraitementOD1 ? this.Prix_TraitementOD1 : 0
      const traitementOG =  this.Prix_TraitementOG1 ? this.Prix_TraitementOG1 : 0
      const colorationOD = this.Prix_ColorationOD1 ? this.Prix_ColorationOD1 : 0
      const colorationOG = this.Prix_ColorationOG1 ? this.Prix_ColorationOG1 : 0
      const fabricationOD = this.Prix_FabricationOD1 ? this.Prix_FabricationOD1 : 0
      const fabricationOG = this.Prix_FabricationOG1 ? this.Prix_FabricationOG1 : 0

      return (+traitementOD + +traitementOG + +colorationOD + +colorationOG + +fabricationOD + +fabricationOG)
    },
    totalVerreProposition2 () {
      return (+this.PrixVerG2 + +this.PrixVerD2)
    },
    totalSupProposition2 () {
      const traitementOD = this.Prix_TraitementOD2 ? this.Prix_TraitementOD2 : 0
      const traitementOG =  this.Prix_TraitementOG2 ? this.Prix_TraitementOG2 : 0
      const colorationOD = this.Prix_ColorationOD2 ? this.Prix_ColorationOD2 : 0
      const colorationOG = this.Prix_ColorationOG2 ? this.Prix_ColorationOG2 : 0
      const fabricationOD = this.Prix_FabricationOD2 ? this.Prix_FabricationOD2 : 0
      const fabricationOG = this.Prix_FabricationOG2 ? this.Prix_FabricationOG2 : 0

      return (+traitementOD + +traitementOG + +colorationOD + +colorationOG + +fabricationOD + +fabricationOG)
    },
    totalVerreProposition3 () {
      return (+this.PrixVerG3 + +this.PrixVerD3)
    },
    totalSupProposition3 () {
      const traitementOD = this.Prix_TraitementOD3 ? this.Prix_TraitementOD3 : 0
      const traitementOG =  this.Prix_TraitementOG3 ? this.Prix_TraitementOG3 : 0
      const colorationOD = this.Prix_ColorationOD3 ? this.Prix_ColorationOD3 : 0
      const colorationOG = this.Prix_ColorationOG3 ? this.Prix_ColorationOG3 : 0
      const fabricationOD = this.Prix_FabricationOD3 ? this.Prix_FabricationOD3 : 0
      const fabricationOG = this.Prix_FabricationOG3 ? this.Prix_FabricationOG3 : 0

      return (+traitementOD + +traitementOG + +colorationOD + +colorationOG + +fabricationOD + +fabricationOG)
    },
    totalSansRemise () {
      return  ((+(this.PrixMont1 ? this.PrixMont1 : 0) + +(this.PrixVerG1 ? this.PrixVerG1 : 0) + +(this.PrixVerD1 ? this.PrixVerD1 : 0)) +  (+(this.PrixMont2 ? this.PrixMont2 : 0) + +(this.PrixVerG2 ? this.PrixVerG2 : 0) + +(this.PrixVerD2 ? this.PrixVerD2 : 0)) + (+(this.PrixMont3 ? this.PrixMont3 : 0) + +(this.PrixVerG3 ? this.PrixVerG3 : 0) + +(this.PrixVerD3 ? this.PrixVerD3 : 0)))
    },
    Societes () {
      return this.$store.state.societe.societes
    },
    assuranceData () {
      return this.$store.state.assurance.assurances
    },
    calculNet () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.factureNet = (this.totalSansRemise - +this.RemiseCom).toFixed(0)
      return this.factureNet
    },
    calculParClient () {
      const totalMonture = (+this.PrixMont1 +  +this.PrixMont2 +  +this.PrixMont3).toFixed(0)
      const totalVerre = (+this.PrixVerG1 + +this.PrixVerD1 + +this.PrixVerG2 + +this.PrixVerD2 + +this.PrixVerG3 + +this.PrixVerD3).toFixed(0)

      if ((this.plafonM > 0 || this.ticketM > 0) && this.plafonV === 0 && this.ticketV === 0 && this.plafon === 0 && this.ticket === 0) {
        if (totalMonture >= this.plafonM) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - +this.plafonM)
        } else if (this.ticketM > 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((totalMonture * this.ticketM) / 100).toFixed(0) + +totalVerre
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((+this.plafonM - totalMonture)).toFixed(0) + +totalVerre
        }
      } else if ((this.plafonV > 0 || this.ticketV > 0) && this.plafonM === 0 && this.ticketM === 0 && this.plafon === 0 && this.ticket === 0) {
        if (totalVerre >= this.plafonV) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - +this.plafonV)
        } else if (this.ticketV > 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((totalVerre * this.ticketV) / 100).toFixed(0) + +totalMonture
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((+this.plafonV - totalVerre)).toFixed(0) + +totalMonture
        }
      } else if ((this.plafonV > 0 || this.ticketV > 0) && (this.plafonM > 0 || this.ticketM > 0) && this.plafon === 0 && this.ticket === 0) {
        if (totalVerre >= this.plafonV && totalMonture >= this.plafonM) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - (+this.plafonV + +this.plafonM))

        } else if (totalVerre >= this.plafonV && totalMonture < this.plafonM) {
          const montantTicketM = ((totalMonture * this.ticketM).toFixed(0) / 100)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - (+this.plafonV + +montantTicketM))

        } else if (totalVerre < this.plafonV && totalMonture >= this.plafonM) {
          const montantTicketV = ((totalVerre * this.ticketV).toFixed(0) / 100)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - (+this.plafonM + +montantTicketV))

        } else if (this.ticketV > 0 && this.ticketM > 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((totalVerre * this.ticketV) / 100).toFixed(0) + ((totalMonture * this.ticketM) / 100).toFixed(0)
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((+this.plafonV - totalVerre)).toFixed(0) + ((+this.plafonM - totalMonture)).toFixed(0)
        }
      } else if ((this.plafonV > 0 || this.ticketV > 0) && (this.plafonM > 0 || this.ticketM > 0) && (this.plafon > 0 || this.ticket > 0)) {
        if (this.factureNet >= this.plafon) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - +this.plafon).toFixed(0)

        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((+this.factureNet * this.ticket).toFixed(0) / 100)
          /* si ticker moderateur monture ou verre exist */
        }
      } else if ((this.plafonV === 0 && this.ticketV === 0) && (this.plafonM === 0 && this.ticketM === 0) && (this.plafon > 0 || this.ticket > 0)) {
        if (this.factureNet >= this.plafon) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = (+this.factureNet - +this.plafon).toFixed(0)

        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = ((+this.factureNet * this.ticket).toFixed(0) / 100)
          /* si ticker moderateur monture ou verre exist */
        }
      } else if (this.factureNet >= this.plafon) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.PartClient = (+this.factureNet - +this.plafon).toFixed(0)

      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.PartClient = ((+this.factureNet * this.ticket).toFixed(0) / 100)
        /* si ticker moderateur monture ou verre exist */
      }

      return this.PartClient
    },
    calculParAssurance () {
      /* const montontAppliqueTicketModerateur =
      const parAssTheorique = this.qtePaire === 2 ? this.factureNet : (+this.PrixMont1 + +this.PrixVerG1 + +this.PrixVerD1) */

      if (this.factureNet >= this.plafon) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.PartAssu = this.plafon
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.PartAssu = (+this.factureNet - +this.PartClient).toFixed(0)
      }
      return this.PartAssu
    },
    totalSuplementProposition1 () {
      const totalsupOD1 = this.SuplementOD1 ? (this.SuplementOD1.length > 0 ? this.SuplementOD1.reduce((acc, sup) => {
        return acc + (+sup.prix_Traitement)
      }, 0) : 0) : 0

      const totalsupOG1 = this.SuplementOG1 ? (this.SuplementOG1.length > 0 ? this.SuplementOG1.reduce((acc, sup) => {
        return acc + (+sup.prix_Traitement)
      }, 0) : 0) : 0
      return (totalsupOD1 + totalsupOG1)
    },
    totalSuplementProposition2 () {
      const totalsupOD2 = this.SuplementOD2 ? (this.SuplementOD2.length > 0 ? this.SuplementOD2.reduce((acc, sup) => {
        return acc + (+sup.prix_Traitement)
      }, 0) : 0) : 0

      const totalsupOG2 = this.SuplementOG2 ? (this.SuplementOG2.length > 0 ? this.SuplementOG2.reduce((acc, sup) => {
        return acc + (+sup.prix_Traitement)
      }, 0) : 0) : 0
      return (totalsupOD2 + totalsupOG2)
    },
    totalSuplementProposition3 () {
      const totalsupOD3 = this.SuplementOD3 ? (this.SuplementOD3.length > 0 ? this.SuplementOD3.reduce((acc, sup) => {
        return acc + (+sup.prix_Traitement)
      }, 0) : 0) : 0

      const totalsupOG3 = this.SuplementOG3 ? (this.SuplementOG3.length > 0 ? this.SuplementOG3.reduce((acc, sup) => {
        return acc + (+sup.prix_Traitement)
      }, 0) : 0) : 0
      return (totalsupOD3 + totalsupOG3)
    }
  },
  methods: {
    /* conversion nombre en lettre */
    Unite (nombre) {
      let unite = ''
      switch (nombre) {
      case 0:
        unite = 'zéro'
        break
      case 1:
        unite = 'un'
        break
      case 2:
        unite = 'deux'
        break
      case 3:
        unite = 'trois'
        break
      case 4:
        unite = 'quatre'
        break
      case 5:
        unite = 'cinq'
        break
      case 6:
        unite = 'six'
        break
      case 7:
        unite = 'sept'
        break
      case 8:
        unite = 'huit'
        break
      case 9:
        unite = 'neuf'
        break
      }

      return unite
    },
    Dizaine (nombre) {
      let dizaine = ''
      switch (nombre) {
      case 10:
        dizaine = 'dix'
        break
      case 11:
        dizaine = 'onze'
        break
      case 12:
        dizaine = 'douze'
        break
      case 13:
        dizaine = 'treize'
        break
      case 14:
        dizaine = 'quatorze'
        break
      case 15:
        dizaine = 'quinze'
        break
      case 16:
        dizaine = 'seize'
        break
      case 17:
        dizaine = 'dix-sept'
        break
      case 18:
        dizaine = 'dix-huit'
        break
      case 19:
        dizaine = 'dix-neuf'
        break
      case 20:
        dizaine = 'vingt'
        break
      case 30:
        dizaine = 'trente'
        break
      case 40:
        dizaine = 'quarante'
        break
      case 50:
        dizaine = 'cinquante'
        break
      case 60:
        dizaine = 'soixante'
        break
      case 70:
        dizaine = 'soixante-dix'
        break
      case 80:
        dizaine = 'quatre-vingt'
        break
      case 90:
        dizaine = 'quatre-vingt-dix'
        break
      }
      return dizaine
    },
    NumberToLetter (nombre) {
      let quotient = 0, reste = 0
      let numberToLetter = ''
      //__________________________________

      if (nombre.toString().replace(/ /gi, '').length > 15) {
        return 'dépassement de capacité'
      }
      if (isNaN(nombre.toString().replace(/ /gi, ''))) {
        return 'Nombre non valide'
      }

      const nb = parseFloat(nombre.toString().replace(/ /gi, ''))
      if (Math.ceil(nb) !== nb) {
        return  'Nombre avec virgule non géré.'
      }

      const n = nb.toString().length
      switch (n) {
      case 1:
        numberToLetter = this.Unite(nb)
        break
      case 2:
        if (nb > 19) {
          quotient = Math.floor(nb / 10)
          reste = nb % 10
          if (nb < 71 || (nb > 79 && nb < 91)) {
            if (reste === 0) numberToLetter = this.Dizaine(quotient * 10)
            if (reste === 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-et-${  this.Unite(reste)}`
            if (reste > 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-${  this.Unite(reste)}`
          } else numberToLetter = `${this.Dizaine((quotient - 1) * 10)  }-${  this.Dizaine(10 + reste)}`
        } else numberToLetter = this.Dizaine(nb)
        break
      case 3:
        quotient = Math.floor(nb / 100)
        reste = nb % 100
        if (quotient === 1 && reste === 0) numberToLetter = 'cent'
        if (quotient === 1 && reste !== 0) numberToLetter = `cent ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.Unite(quotient)  } cents`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.Unite(quotient)  } cent ${  this.NumberToLetter(reste)}`
        break
      case 4 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 5 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 6 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 7:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 8:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 9:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 10:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 11:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 12:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 13:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 14:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 15:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      }//fin switch
      /*respect de l'accord de quatre-vingt*/
      if (numberToLetter.substr(numberToLetter.length - 'quatre-vingt'.length, 'quatre-vingt'.length) === 'quatre-vingt') numberToLetter = `${numberToLetter  }s`

      return numberToLetter.toUpperCase()
    },
    /* fin conversion nombre en lettre */
    ConvertRemise () {
      if (this.RemiseCom <= 100) {
        this.RemiseCom = Math.round((((+this.PrixMont1 + +this.PrixVerG1 + +this.PrixVerD1 + this.totalSuplementProposition1) +  (+this.PrixMont2 + +this.PrixVerG2 + +this.PrixVerD2 + this.totalSuplementProposition2) + (+this.PrixMont3 + +this.PrixVerG3 + +this.PrixVerD3 + this.totalSuplementProposition3)) * this.RemiseCom) / 100)
      }
    },

    generateReport () {

      this.$vs.loading({
        type: 'sound'
      })

      setTimeout(() => {
        this.$vs.loading.close()
      }, 20000)

      this.$refs.html2Pdf.generatePdf()

    },

    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },

    getMarqueName (id) {
      const singleMarque = this.$store.getters['marque/getMarqueById'](id)
      return singleMarque ? singleMarque.Libelle_marq : ''
    },

    OrdonnanceValue (refOrd) {

      const ordonnance = this.client.ordonnances.find((o) => o.RefOrd === refOrd)
      this.SphVD = ordonnance.OdVlSph
      this.CylVD = ordonnance.OdVlCyl
      this.AxeVD = ordonnance.OdVlAxe
      this.AddVD = ordonnance.OdAddition
      this.SphVG = ordonnance.OgVlSph
      this.CylVG = ordonnance.OgVlCyl
      this.AxeVG = ordonnance.OgVlAxe
      this.AddVG = ordonnance.OgAddition
    },

    PriseEnChargeValue (RefPEC) {
      const pec = RefPEC ? this.client.priseEnCharges.find((p) => p.RefPEC === RefPEC) : null
      const societe = pec ? this.Societes.find((s) => s._id === pec.IdSociete) : ''
      const ass = pec ? this.assuranceData.find((a) => a._id === pec.IdAssurance) : ''

      this.entrepise = societe ? societe.NomSociete : ''
      this.assuran = ass ? ass.Nom_Abrege : ''
      this.matricule = pec ? pec.NumSalarie : ''
      this.assur = pec ? pec.Assurer : ''
      this.patient = pec ? pec.Patient : ''
      this.ticket = pec ? pec.TickForf : 0
      this.ticketM = pec ? pec.TickMont : 0
      this.ticketV = pec ? pec.TickVerr : 0
      this.plafon = pec ? pec.PlafForf : 0
      this.plafonM = pec ? pec.PlafMont : 0
      this.plafonV = pec ? pec.PlafVerr : 0
      this.qtePaire = pec ? pec.QtePair : 0
    },

    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdOfficine = this.activeUserInfos.IdOfficine
      this.IdClient = this.activeUserInfos._id
    },
    getClientsOfficine (id) {
      this.$store.dispatch('client/getClientsOfficine', id)
        .then(() => {
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getClientById () {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('client/getClientById', this.$route.params.clientId)
        .then((resp) => {
          this.client = resp.data
          this.IdClient = this.client._id

          const commande = this.client.commandes.find((c) => c.RefCmmd === this.$route.params.RefCmmd)

          this.RefCmmd = commande.RefCmmd
          this.RefPEC = commande.RefPEC
          this.Idprescripteur = commande.Idprescripteur
          this.IdUser = commande.IdUser
          this.IdPartenaire = commande.IdPartenaire
          this.DatePrescrit = commande.DatePrescrit
          this.DateCommd = commande.DateCommd

          this.SphVD = commande.SphVD
          this.SphVG = commande.SphVG
          this.CylVD = commande.CylVD
          this.CylVG = commande.CylVG
          this.AxeVD = commande.AxeVD
          this.AxeVG = commande.AxeVG
          this.AddVD = commande.AddVD
          this.AddVG = commande.AddVG

          this.EdVD = commande.EdVD
          this.EdVG = commande.EdVG
          this.HdVD = commande.HdVD
          this.HdVG = commande.HdVG

          this.EcartVlOD = commande.EcartVlOD
          this.EcartVpOD = commande.EcartVpOD
          this.EcartVlOG = commande.EcartVlOG
          this.EcartVpOG = commande.EcartVpOG

          this.Ht_Mont1 = commande.Ht_Mont1
          this.Ht_Mont2 = commande.Ht_Mont2
          this.Ht_Mont3 = commande.Ht_Mont3

          this.Dctr_Mont1 = commande.Dctr_Mont1
          this.Dctr_Mont2 = commande.Dctr_Mont2
          this.Dctr_Mont3 = commande.Dctr_Mont3

          this.IdMont1 = commande.IdMont1
          this.OrigineMont1 = commande.OrigineMont1
          this.libelleMontureClient = commande.libelleMontureClient
          this.PrixMont1 = commande.PrixMont1
          this.RefVerG1 = commande.RefVerG1
          this.PrixVerG1 = commande.PrixVerG1
          this.RefVerD1 = commande.RefVerD1
          this.PrixVerD1 = commande.PrixVerD1
          this.SuplementOD1 = commande.SuplementOD1
          this.SuplementOG1 = commande.SuplementOG1

          this.IdMont2 = commande.IdMont2
          this.OrigineMont2 = commande.OrigineMont2
          this.libelleMontureClient2 = commande.libelleMontureClient2
          this.PrixMont2 = commande.PrixMont2
          this.RefVerG2 = commande.RefVerG2
          this.PrixVerG2 = commande.PrixVerG2
          this.RefVerD2 = commande.RefVerD2
          this.PrixVerD2 = commande.PrixVerD2
          this.SuplementOD2 = commande.SuplementOD2
          this.SuplementOG2 = commande.SuplementOG2

          this.IdMont3 = commande.IdMont3
          this.OrigineMont3 = commande.OrigineMont3
          this.libelleMontureClient3 = commande.libelleMontureClient3
          this.PrixMont3 = commande.PrixMont3
          this.RefVerG3 = commande.RefVerG3
          this.PrixVerG3 = commande.PrixVerG3
          this.RefVerD3 = commande.RefVerD3
          this.PrixVerD3 = commande.PrixVerD3
          this.SuplementOD3 = commande.SuplementOD3
          this.SuplementOG3 = commande.SuplementOG3

          this.Id_FoyerOD1 = commande ? commande.Id_FoyerOD1 : null
          this.IdGammeVerrOD1 = commande ? commande.IdGammeVerrOD1 : null
          this.Id_OptionVerreOD1 = commande ? commande.Id_OptionVerreOD1 : null
          this.Prix_OptionVerreOD1 = commande ? commande.Prix_OptionVerreOD1 : null
          this.Id_MatiereOD1 = commande ? commande.Id_MatiereOD1 : null
          this.Id_IndiceOD1 = commande ? commande.Id_IndiceOD1 : null
          this.IdFourVerrOD1 = commande ? commande.IdFourVerrOD1 : null
          this.Id_FabricationOD1 = commande ? commande.Id_FabricationOD1 : null
          this.Prix_FabricationOD1 = commande ? commande.Prix_FabricationOD1 : null
          this.Id_ColorationOD1 = commande ? commande.Id_ColorationOD1 : null
          this.Prix_ColorationOD1 = commande ? commande.Prix_ColorationOD1 : null
          this.Id_TraitementOD1 = commande ? commande.Id_TraitementOD1 : null
          this.Prix_TraitementOD1 = commande ? commande.Prix_TraitementOD1 : null

          this.Id_FoyerOG1 = commande ? commande.Id_FoyerOG1 : null
          this.IdGammeVerrOG1 = commande ? commande.IdGammeVerrOG1 : null
          this.Id_OptionVerreOG1 = commande ? commande.Id_OptionVerreOG1 : null
          this.Prix_OptionVerreOG1 = commande ? commande.Prix_OptionVerreOG1 : null
          this.Id_MatiereOG1 = commande ? commande.Id_MatiereOG1 : null
          this.Id_IndiceOG1 = commande ? commande.Id_IndiceOG1 : null
          this.IdFourVerrOG1 = commande ? commande.IdFourVerrOG1 : null
          this.Id_FabricationOG1 = commande ? commande.Id_FabricationOG1 : null
          this.Prix_FabricationOG1 = commande ? commande.Prix_FabricationOG1 : null
          this.Id_ColorationOG1 = commande ? commande.Id_ColorationOG1 : null
          this.Prix_ColorationOG1 = commande ? commande.Prix_ColorationOG1 : null
          this.Id_TraitementOG1 = commande ? commande.Id_TraitementOG1 : null
          this.Prix_TraitementOG1 = commande ? commande.Prix_TraitementOG1 : null

          this.Id_FoyerOD2 = commande ? commande.Id_FoyerOD2 : null
          this.IdGammeVerrOD2 = commande ? commande.IdGammeVerrOD2 : null
          this.Id_OptionVerreOD2 = commande ? commande.Id_OptionVerreOD2 : null
          this.Prix_OptionVerreOD2 = commande ? commande.Prix_OptionVerreOD2 : null
          this.Id_MatiereOD2 = commande ? commande.Id_MatiereOD2 : null
          this.Id_IndiceOD2 = commande ? commande.Id_IndiceOD2 : null
          this.IdFourVerrOD2 = commande ? commande.IdFourVerrOD2 : null
          this.Id_FabricationOD2 = commande ? commande.Id_FabricationOD2 : null
          this.Prix_FabricationOD2 = commande ? commande.Prix_FabricationOD2 : null
          this.Id_ColorationOD2 = commande ? commande.Id_ColorationOD2 : null
          this.Prix_ColorationOD2 = commande ? commande.Prix_ColorationOD2 : null
          this.Id_TraitementOD2 = commande ? commande.Id_TraitementOD2 : null
          this.Prix_TraitementOD2 = commande ? commande.Prix_TraitementOD2 : null

          this.Id_FoyerOG2 = commande ? commande.Id_FoyerOG2 : null
          this.IdGammeVerrOG2 = commande ? commande.IdGammeVerrOG2 : null
          this.Id_OptionVerreOG2 = commande ? commande.Id_OptionVerreOG2 : null
          this.Prix_OptionVerreOG2 = commande ? commande.Prix_OptionVerreOG2 : null
          this.Id_MatiereOG2 = commande ? commande.Id_MatiereOG2 : null
          this.Id_IndiceOG2 = commande ? commande.Id_IndiceOG2 : null
          this.IdFourVerrOG2 = commande ? commande.IdFourVerrOG2 : null
          this.Id_FabricationOG2 = commande ? commande.Id_FabricationOG2 : null
          this.Prix_FabricationOG2 = commande ? commande.Prix_FabricationOG2 : null
          this.Id_ColorationOG2 = commande ? commande.Id_ColorationOG2 : null
          this.Prix_ColorationOG2 = commande ? commande.Prix_ColorationOG2 : null
          this.Id_TraitementOG2 = commande ? commande.Id_TraitementOG2 : null
          this.Prix_TraitementOG2 = commande ? commande.Prix_TraitementOG2 : null

          this.Id_FoyerOD3 = commande ? commande.Id_FoyerOD3 : null
          this.IdGammeVerrOD3 = commande ? commande.IdGammeVerrOD3 : null
          this.Id_OptionVerreOD3 = commande ? commande.Id_OptionVerreOD3 : null
          this.Prix_OptionVerreOD3 = commande ? commande.Prix_OptionVerreOD3 : null
          this.Id_MatiereOD3 = commande ? commande.Id_MatiereOD3 : null
          this.Id_IndiceOD3 = commande ? commande.Id_IndiceOD3 : null
          this.IdFourVerrOD3 = commande ? commande.IdFourVerrOD3 : null
          this.Id_FabricationOD3 = commande ? commande.Id_FabricationOD3 : null
          this.Prix_FabricationOD3 = commande ? commande.Prix_FabricationOD3 : null
          this.Id_ColorationOD3 = commande ? commande.Id_ColorationOD3 : null
          this.Prix_ColorationOD3 = commande ? commande.Prix_ColorationOD3 : null
          this.Id_TraitementOD3 = commande ? commande.Id_TraitementOD3 : null
          this.Prix_TraitementOD3 = commande ? commande.Prix_TraitementOD3 : null

          this.Id_FoyerOG3 = commande ? commande.Id_FoyerOG3 : null
          this.IdGammeVerrOG3 = commande ? commande.IdGammeVerrOG3 : null
          this.Id_OptionVerreOG3 = commande ? commande.Id_OptionVerreOG3 : null
          this.Prix_OptionVerreOG3 = commande ? commande.Prix_OptionVerreOG3 : null
          this.Id_MatiereOG3 = commande ? commande.Id_MatiereOG3 : null
          this.Id_IndiceOG3 = commande ? commande.Id_IndiceOG3 : null
          this.IdFourVerrOG3 = commande ? commande.IdFourVerrOG3 : null
          this.Id_FabricationOG3 = commande ? commande.Id_FabricationOG3 : null
          this.Prix_FabricationOG3 = commande ? commande.Prix_FabricationOG3 : null
          this.Id_ColorationOG3 = commande ? commande.Id_ColorationOG3 : null
          this.Prix_ColorationOG3 = commande ? commande.Prix_ColorationOG3 : null
          this.Id_TraitementOG3 = commande ? commande.Id_TraitementOG3 : null
          this.Prix_TraitementOG3 = commande ? commande.Prix_TraitementOG3 : null

          this.Remarque = commande.Remarque
          this.RemiseCom = commande.RemiseCom
          this.PartClient = commande.PartClient
          this.PartAssu = commande.PartAssu
          /* this.IdClient = this.commande. */

          this.PriseEnChargeValue(this.RefPEC)
          if (commande.IdMont1) {
            this.$store.dispatch('stock/getArticleById', commande.IdMont1)
              .then((res) => {
                this.montureClearName1 = `${res.data.Etiquette} | ${this.getMarqueName(res.data.IdMarque)} ${res.data.Modele} ${res.data.Couleur}`
              })
              .catch(err => {
                console.error(err)
                this.$vs.notify({
                  title: 'error',
                  text: `${err}`,
                  iconPack: 'feather',
                  icon: 'icon-error',
                  color: 'danger'
                })
              })
          }
          if (commande.IdMont2) {
            this.$store.dispatch('stock/getArticleById', commande.IdMont2)
              .then((res) => {
                this.montureClearName2 = `${res.data.Etiquette} | ${this.getMarqueName(res.data.IdMarque)} ${res.data.Modele} ${res.data.Couleur}`
              })
              .catch(err => {
                console.error(err)
                this.$vs.notify({
                  title: 'error',
                  text: `${err}`,
                  iconPack: 'feather',
                  icon: 'icon-error',
                  color: 'danger'
                })
              })
          }
          if (commande.IdMont3) {
            this.$store.dispatch('stock/getArticleById', commande.IdMont3)
              .then((res) => {
                this.montureClearName3 = `${res.data.Etiquette} | ${this.getMarqueName(res.data.IdMarque)} ${res.data.Modele} ${res.data.Couleur}`
              })
              .catch(err => {
                console.error(err)
                this.$vs.notify({
                  title: 'error',
                  text: `${err}`,
                  iconPack: 'feather',
                  icon: 'icon-error',
                  color: 'danger'
                })
              })
          }
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getSocietes () {
      this.$store.dispatch('societe/getSocietes')
        .then(() => {
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getAssurances () {
      this.$store.dispatch('assurance/getAssurances')
        .then(() => {

        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .then(() => {

        })
        .catch(err => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getTraitementById (id) {
      return this.$store.getters['verre/getTraitementById'](id)
    },

    getFabricationById (id) {
      return this.$store.getters['verre/getFabricationById'](id)
    },

    getColorationById (id) {
      return this.$store.getters['verre/getColorationById'](id)
    },
    getTraitement () {
      this.$store.dispatch('verre/getTraitement')
        .catch((error) => { console.log(error) })
    },
    getFabrications () {
      this.$store.dispatch('verre/getFabrications')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getColorations () {
      this.$store.dispatch('verre/getColorations')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    }

  },
  created () {
    this.getClientById()
    if (!(this.$store.state.verre.traitements.length > 0)) this.getTraitement()
    if (!(this.$store.state.verre.fabrications.length > 0)) this.getFabrications()
    if (!(this.$store.state.verre.colorations.length > 0)) this.getColorations()

    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
    if (!(this.$store.state.societe.societes.length > 0)) this.getSocietes()
    if (!(this.$store.state.assurance.assurances.length > 0)) this.getAssurances()
    this.activeUserInfo()
  }
}
</script>

<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
th, td {
  border: 1px solid black;
  padding: 4px;
}
</style>
